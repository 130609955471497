<template>
  <div>
    <section class="card-success">
      <div class="card-success__ico">
        <img
          src="../../../assets/img/ico/ico-color-success.svg"
          alt="¡Felicidades!"
        />
      </div>
      <h3 class="card-success__header">{{ $t("freeAdd.firstProductTitle") }}</h3>
      <div class="card-success__content">
        {{ $t("freeAdd.firstProductSubtitle") }}
      </div>
      <div class="card-success__actions card-success__actions--vertical card-success__actions--50">
        <router-link
          to="mi-lista"
          class="button button--primary"
        >
          {{ $t("freeAdd.firstProductButton") }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FirstProductAddedToList",
};
</script>
